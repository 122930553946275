/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, SeparateLine, Subtitle, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader />

        <Column className="--center pb--50 pt--80" name={"urj63ee7nfn"} style={{"backgroundColor":"rgba(206,178,162,1)"}}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center fs--43" content={"<span style=\"color: var(--white);\">Ceník</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pt--20" name={"eejsyj7w7io"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box text-box--center fs--20" style={{"maxWidth":654}} content={"Stejně jako je individuální péče o pejsky, jsou individuální i ceny. Nejsem agentura, každý majitel má trochu jiné požadavky a proto se konkrétně domluvíme při osobním setkání, až budu znát všechny podrobnosti. Vždy se ale snažím, aby byly spokojené všechny tři strany. Majitel, pejsek i já :)."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="css-1frsgf1" name={"pmknj6jj81b"} style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":127}} layout={"l3"} parallax={false} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/26402/7e8b458feac24babab2da41a977f6129_s=3000x_.jpg);
    }
  
    `}>
        </SeparateLine>


        <Column className="pb--25 pt--16" name={"v0kt1b5tug"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--bottom" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box fs--26" content={"Od čeho se odvíjí cena?"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Cena se odvíjí od spousty věcí. Primárně ale od toho, jak moc je potřeba se pejskovi věnovat. Pokud jde o hlídání, tak zda vydrží nějakou dobu doma sám nebo je potřeba s ním být stále, kolikrát denně a jak často je potřeba ho venčit, jak moc je socializovaný, zda vychází bez problému i s jinými pejsky, jestli jde o hlídání u majitele či u mě doma. V rámci venčení je to o něco jednodušší, ale určitě záleží na čase a délce venčení, lokaci, náplni procházky, atd. Výcviky sestavuji individuálně dle požadavků majitele a současných dovedností pejska. Záleží tedy na rozsahu a náročnosti lekcí.&nbsp;"}>
              </Text>

              <Subtitle className="subtitle-box fs--26" content={"Liší se cena při dlouhodobé spolupráci? "}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Když se s klienty domlouvám na odměně, určitě hraje roli i to, zda bude spolupráce dlouhodobá nebo jde pouze o jednorázovou výpomoc. Jsem ochotná a otevřená přistoupit na lepší podmínky pro klienta, když budu vědět, že bude hlídání/venčení/lekce pravidelné nebo opakované. Důvod je prostý, s pejsky které už znám a jsme na sebe zvyklí se mi lépe pracuje.  \n"}>
              </Text>

              <Subtitle className="subtitle-box fs--26" content={"Jsou sazby vyšší, pokud potřebuji hlídání na poslední chvíli? "}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":""}} content={"Pokud se jednou domluvíme na ceně, která bude vyhovovat všem, nebudu na ní nic měnit, ani když půjde o hlídání na poslední chvíli. Může se však stát, že v daný termín už bohužel nebudu mít čas, proto je lepší rezervovat si termíny předem. Vždy se ale snažím vyjít vstříc, pokud to jde. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"paticka"} style={{"backgroundColor":"var(--color-blend--95)"}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Image src={"https://cdn.swbpg.com/o/26402/df60f83ccf2f4be8867ec8bcdeed2086.svg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

              <Text className="text-box" content={"info@dogmom.cz<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}